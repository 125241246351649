import React from 'react';

import { list } from './landing-page-product-rows.module.scss';
import { ILandingPageRow } from '../../models/landing-page.model';

import LandingPageProductRow from '../atoms/landing-page-product-row';

export interface ILandingPageProductRowsProps {
    rows: ILandingPageRow[];
    className?: string;
}

const LandingPageProductRows: React.FC<ILandingPageProductRowsProps> = ({
    rows,
    className = '',
}) => {
    if (rows.length === 0) return null;

    return (
        <ul className={`${list} ${className}`}>
            {rows.map((row) => (
                <LandingPageProductRow row={row} key={row.id} />
            ))}
        </ul>
    );
};

export default LandingPageProductRows;

import React, { useEffect, useState } from 'react';
import { SwiperOptions } from 'swiper';

import { container } from './landing-page-bestsellers.module.scss';
import { useTranslation } from '../../hooks/use-translation';
import { config } from '../../config';
import { ILandingPageBestseller } from '../../models/landing-page.model';

import ProductSlider from './product-slider';

export interface ILandingPageBestsellersProps {
    className?: string;
    data: ILandingPageBestseller;
    autoplayTime?: number;
}

const { translationKeys } = config;

const LandingPageBestsellers: React.FC<ILandingPageBestsellersProps> = ({
    className = '',
    data,
    autoplayTime = 4000,
}) => {
    const { title } = useTranslation(data, translationKeys.landingPageBestseller);
    const [sliderSettings, setSliderSettings] = useState<SwiperOptions>(defaultSliderSettings);

    useEffect(() => {
        setSliderSettings({
            ...defaultSliderSettings,
            autoplay: { ...defaultSliderSettings.autoplay, delay: autoplayTime },
        });
    }, [autoplayTime]);

    if (!data?.products) return null;
    console.log(data.products);
    return (
        <div className={className}>
            <div className={container} style={{ backgroundColor: data.backgroundColor }}>
                <ProductSlider
                    products={data.products}
                    title={title}
                    sliderSettings={sliderSettings}
                />
            </div>
        </div>
    );
};

const defaultSliderSettings = {
    navigation: false,
    autoplay: { disableOnInteraction: false },
    pagination: false,
    speed: 500,
    slidesPerView: 1,
    loop: true,
    breakpoints: {
        700: {
            slidesPerView: 2,
        },
        1024: {
            slidesPerView: 3,
        },
    },
};

export default LandingPageBestsellers;

import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
    layout,
    title as titleClass,
    intro as introClass,
    products,
    productsGrid,
    bestsellers,
    bottom,
} from './landing-page.module.scss';
import { config } from '../config';
import { ILandingPage } from '../models/landing-page.model';
import { useTranslation } from '../hooks/use-translation';
import { getMediaWithRelation } from '../utils/get-relation';
import { getBreadcrumbItem } from '../utils/get-breadcrumbs';

import MainLayout from '../layouts/main-layout';
import LandingPageProduct from '../components/organisms/landing-page-product';
import LandingPageBestsellers from '../components/organisms/landing-page-bestsellers';
import SEO from '../components/seo';
import Title from '../components/atoms/title';

const { relations, translationKeys } = config;

interface ILandingPageProps {
    readonly data: {
        landingPage: ILandingPage;
    };
}

const LandingPage: React.FC<ILandingPageProps> = ({ data }) => {
    const { landingPage } = data;
    const { title, upTitle } = useTranslation(landingPage, translationKeys.landingPage);
    const bottomMedia = getMediaWithRelation(landingPage.media, relations.secondaryImage);
    const bottomImage = bottomMedia?.remoteImage && getImage(bottomMedia.remoteImage);

    return (
        <>
            <SEO title={`${title}`} />
            <MainLayout
                className={layout}
                bannersData={
                    landingPage.banner && { banner: landingPage.banner, titleData: { Tag: 'h1' } }
                }
                breadcrumbsConfig={{
                    items: [getBreadcrumbItem(landingPage, 'title')],
                }}
            >
                {landingPage.products.length > 0 && (
                    <div className={products}>
                        <Title className={titleClass} intro={upTitle} introClass={introClass}>
                            {title}
                        </Title>
                        <div className={productsGrid}>
                            {landingPage.products.map((product) => (
                                <LandingPageProduct
                                    data={product}
                                    autoplayTime={
                                        landingPage.autoplayTime * MILLISECONDS_MULTIPLIER
                                    }
                                />
                            ))}
                        </div>
                    </div>
                )}

                <LandingPageBestsellers
                    className={bestsellers}
                    data={landingPage.bestseller}
                    autoplayTime={landingPage.autoplayTime * MILLISECONDS_MULTIPLIER}
                />

                {bottomImage && (
                    <GatsbyImage
                        image={bottomImage}
                        className={bottom}
                        alt={`${bottomMedia?.alt}`}
                    />
                )}
            </MainLayout>
        </>
    );
};

const MILLISECONDS_MULTIPLIER = 1000;

export const query = graphql`
    query ($language: String!, $id: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        landingPage(id: { eq: $id }) {
            ...landingPageFields
        }
    }
`;

export default LandingPage;

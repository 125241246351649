import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
    row as rowClass,
    image as imageClass,
    title as titleClass,
} from './landing-page-product-row.module.scss';
import { useTranslation } from '../../hooks/use-translation';
import { config } from '../../config';
import { getMediaWithRelation } from '../../utils/get-relation';
import { ILandingPageRow } from '../../models/landing-page.model';

import Markdown from '../hoc/markdown';

export interface ILandingPageProductRowProps {
    row: ILandingPageRow;
}

const { relations, translationKeys } = config;

const LandingPageProductRow: React.FC<ILandingPageProductRowProps> = ({ row }) => {
    const image = getMediaWithRelation(row.media, relations.mainImage);
    const { title } = useTranslation(row, translationKeys.landingPageRow);

    const remoteImage = image && image.remoteImage && getImage(image.remoteImage);

    return (
        <li className={rowClass}>
            {remoteImage && (
                <GatsbyImage
                    image={remoteImage}
                    alt={`${image?.alt}`}
                    className={imageClass}
                    objectFit={'contain'}
                />
            )}
            <Markdown className={titleClass}>{title}</Markdown>
        </li>
    );
};

export default LandingPageProductRow;

// extracted by mini-css-extract-plugin
export var banner = "landing-page-module--banner--0GHz+";
export var bestsellers = "landing-page-module--bestsellers--H5qRU";
export var bottom = "landing-page-module--bottom--2seE8";
export var imageBox = "landing-page-module--image-box--GJvbj";
export var intro = "landing-page-module--intro--LuH0q";
export var layout = "landing-page-module--layout--Pd2a+";
export var products = "landing-page-module--products--4lVe7";
export var productsContainer = "landing-page-module--products-container--gZnhv";
export var productsGrid = "landing-page-module--products-grid--tYIdN";
export var productsRatio = "landing-page-module--products-ratio--+BjYw";
export var productsTitle = "landing-page-module--products-title--NeYnO";
export var ratio = "landing-page-module--ratio--reuyD";
export var title = "landing-page-module--title--GTQbu";
// extracted by mini-css-extract-plugin
export var button = "landing-page-product-module--button--mXeL3";
export var caption = "landing-page-product-module--caption--RQ0J6";
export var collection = "landing-page-product-module--collection--vIulj";
export var collectionTitle = "landing-page-product-module--collection-title--giPTI";
export var container = "landing-page-product-module--container--jTiZb";
export var content = "landing-page-product-module--content--5w9g+";
export var expositionSlider = "landing-page-product-module--exposition-slider--X0qNA";
export var icons = "landing-page-product-module--icons--ODc+o";
export var iconsMobile = "landing-page-product-module--icons-mobile--02tJ7";
export var label = "landing-page-product-module--label--cXHmY";
export var lead = "landing-page-product-module--lead--Pwora";
export var logo = "landing-page-product-module--logo--2U6JZ";
export var mainSlider = "landing-page-product-module--main-slider--Nu0Qx";
export var mainSliderContainer = "landing-page-product-module--main-slider-container--h49ws";
export var mainSliderImage = "landing-page-product-module--main-slider-image--ZihIO";
export var rows = "landing-page-product-module--rows--FbF8k";
export var rowsMobile = "landing-page-product-module--rows-mobile--oKNIf";
import React, { useEffect, useState } from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SwiperOptions } from 'swiper';

import {
    container,
    mainSliderContainer,
    mainSlider,
    mainSliderImage,
    caption as captionClass,
    button,
    label as labelClass,
    content,
    logo as logoClass,
    collection as collectionClass,
    collectionTitle,
    lead as leadClass,
    rows,
    rowsMobile,
    icons,
    iconsMobile,
    expositionSlider,
} from './landing-page-product.module.scss';
import { useTranslation } from '../../hooks/use-translation';
import { config } from '../../config';
import { getAllMediaWithRelations, getMediaWithRelation } from '../../utils/get-relation';
import { ILandingPageProduct } from '../../models/landing-page.model';

import NewSlider from '../hoc/new-slider';
import LandingPageProductRows from '../molecules/landing-page-product-rows';
import Button from '../atoms/button';
import Markdown from '../hoc/markdown';
import ProductLabel from '../atoms/product-label';

export interface ILandingPageProductProps {
    className?: string;
    data: ILandingPageProduct;
    autoplayTime?: number;
}

const { relations, translationKeys } = config;

const LandingPageProduct: React.FC<ILandingPageProductProps> = ({
    className = '',
    data,
    autoplayTime = 4000,
}) => {
    const { t } = useI18next();
    const { labelColor, buttonColor } = data;
    const { label, caption, brandUrl, productUrl, collection, title, lead } = useTranslation(
        data,
        translationKeys.landingPageProducts
    );
    const [sliderSettings, setSliderSettings] = useState<SwiperOptions>(defaultSliderSettings);
    const mainImages = getAllMediaWithRelations(data.media, [relations.mainImage]);
    const expositionImages = getAllMediaWithRelations(data.media, [relations.secondaryImage]);
    const logo = getMediaWithRelation(data.media, relations.landingPageLogo);
    const logoGatsbyImage = logo && logo.remoteImage && getImage(logo.remoteImage);
    const iconsImages = getAllMediaWithRelations(data.media, [relations.landingPageIcons]);
    const variantImage = getMediaWithRelation(data.media, relations.landingPageVariantImage);
    const variantGatsbyImage =
        variantImage && variantImage.remoteImage && getImage(variantImage.remoteImage);

    useEffect(() => {
        setSliderSettings({
            ...defaultSliderSettings,
            autoplay: { delay: autoplayTime },
        });
    }, [autoplayTime]);

    return (
        <div className={className}>
            <div className={container}>
                <div className={mainSliderContainer}>
                    <ProductLabel className={labelClass} label={label} color={labelColor} />
                    {mainImages.length > 0 && (
                        <NewSlider
                            slidesCount={mainImages.length}
                            settings={sliderSettings}
                            showDots={true}
                            layout={'product'}
                            className={mainSlider}
                        >
                            {mainImages.map((image) => {
                                const remoteImage =
                                    image && image.remoteImage && getImage(image.remoteImage);

                                if (!remoteImage) return null;

                                return (
                                    <GatsbyImage
                                        image={remoteImage}
                                        className={mainSliderImage}
                                        alt={`${image?.alt}`}
                                    />
                                );
                            })}
                        </NewSlider>
                    )}
                    <Markdown className={captionClass}>{caption}</Markdown>
                    {variantGatsbyImage && (
                        <GatsbyImage image={variantGatsbyImage} alt={`${variantImage?.alt}`} />
                    )}
                    <Button
                        className={button}
                        as={'link'}
                        to={productUrl}
                        style={{ backgroundColor: buttonColor }}
                    >
                        {t('button.knowProduct')}
                    </Button>
                    <LandingPageProductRows rows={data.rows} className={rowsMobile} />
                </div>

                <div className={content}>
                    <div>
                        {logoGatsbyImage && (
                            <a href={brandUrl} target={'_blank'} rel="nofollow noopener noreferrer">
                                <GatsbyImage
                                    image={logoGatsbyImage}
                                    className={logoClass}
                                    alt={`${logo?.alt}`}
                                />
                            </a>
                        )}

                        <Markdown className={collectionClass}>{collection}</Markdown>
                        <p className={collectionTitle}>{title}</p>
                        <Markdown className={leadClass}>{lead}</Markdown>
                        <LandingPageProductRows rows={data.rows} className={rows} />
                    </div>
                    {iconsImages.length > 0 && (
                        <div className={icons}>
                            {iconsImages.map((image) => {
                                const remoteImage =
                                    image && image.remoteImage && getImage(image.remoteImage);

                                if (!remoteImage) return null;

                                return (
                                    <GatsbyImage
                                        image={remoteImage}
                                        alt={`${image?.alt}`}
                                        objectFit={'contain'}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>

                {expositionImages.length > 0 && (
                    <NewSlider
                        slidesCount={expositionImages.length}
                        settings={{ ...defaultSliderSettings, autoplay: false }}
                        showDots={true}
                        layout={'product'}
                        className={expositionSlider}
                    >
                        {expositionImages.map((image) => {
                            const remoteImage =
                                image && image.remoteImage && getImage(image.remoteImage);

                            if (!remoteImage) return null;

                            return <GatsbyImage image={remoteImage} alt={`${image?.alt}`} />;
                        })}
                    </NewSlider>
                )}
                {iconsImages.length > 0 && (
                    <div className={iconsMobile}>
                        {iconsImages.map((image) => {
                            const remoteImage =
                                image && image.remoteImage && getImage(image.remoteImage);

                            if (!remoteImage) return null;

                            return <GatsbyImage image={remoteImage} alt={`${image?.alt}`} />;
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};

const defaultSliderSettings = {
    navigation: false,
    autoplay: { disableOnInteraction: false },
    pagination: true,
    speed: 500,
    slidesPerView: 1,
    loop: true,
};

export default LandingPageProduct;
